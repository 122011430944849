import { NavLink } from "react-router-dom";

function FeaturedWork({ link, title, url, description }) {
  return (
    <li>
      <NavLink to={`/${url}`} className="mx-auto">
        <img
          src={link}
          alt="project"
          className="w-280 mx-auto"
          loading="lazy"
          width="280"
          height="234.906"
        />
        <h3 className="mt-10 mb-5 hover:text-btn active:text-btn visited:text-text focus:text-text">
          {title}
        </h3>
        <p className="s:max-w-[25rem]">{description}</p>
        <p
          className="hover:text-btn active:text-btn visited:text-text focus:text-text mx-auto mb-16 mt-10"
          aria-hidden="true"
        >{`View Project -->`}</p>
      </NavLink>
    </li>
  );
}

export default FeaturedWork;
