function Figma({ slug, url }) {
  return (
    <iframe
      className="mt-7 mb-14 w-[264px] h-[30rem] mx-auto s:w-11/12 s:h-[40rem] md:!h-[46rem] mx:mt-16 mx:!max-w-[1120px] dt:!h-[685px] dx:!h-[800px] border-hr border border-solid"
      src={url}
      title="Figma prototype"
      loading="eager"
      allowFullScreen
    ></iframe>
  );
}

export default Figma;
