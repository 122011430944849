import persona from "./../../assets/persona.png";

function UxContent({ restData }) {
  return (
    <div className="s:w-11/12 mx:max-w-[1120px] mx-auto">
      {/* Challenge */}
      <div className="ml:flex ml:gap-14">
        <div className="ml:w-1/2">
          <h2 className="mt-20 mb-8">
            {restData.acf.project_challenge_content[0].challenge_title}
          </h2>
          <p className="text-left">
            {restData.acf.project_challenge_content[0].challenge_text}
          </p>
        </div>
        {/* Solution */}
        <div className="ml:w-1/2">
          <h2 className="mt-20 mb-8">
            {restData.acf.project_solution_content[0].a_solution_title}
          </h2>
          <p className="text-left">
            {restData.acf.project_solution_content[0].the_solution_text}
          </p>
        </div>
      </div>
      {/* Research & Planning */}
      {/* <div className="mx:py-10">
        <h2 className="mt-14 mb-8">
          {restData.acf.project_research_content[0].research_title}
        </h2>
        <p className="text-left">
          {restData.acf.project_research_content[0].research_text}
        </p>
      </div>
      <div className="mx:py-5">
        <h3 className="mt-14 mb-8 text-left">
          {restData.acf.project_research_content[0].qualitative_interviews}
        </h3>
        <p className="text-left">
          {
            restData.acf.project_research_content[0]
              .qualitative_interviews_content
          }
        </p>
      </div> */}
      {/* personas */}
      {/* <div className="mx:py-5">
        <h3 className="mt-14 mb-8 text-left">
          {restData.acf.project_research_content[0].personas}
        </h3>
        <p className="text-left">
          {restData.acf.project_research_content[0].personas_text}
        </p>
        <img src={persona} alt="persona" className="mt-8 mx-auto mx:mt-16" />
      </div> */}
      {/* Problem Statement */}
      {/* <div className="mx:py-5">
        <h3 className="mt-14 mb-8 text-left">
          {restData.acf.project_research_content[0].problem_statement_title}
        </h3>
        <p className="text-left mb-14">
          {restData.acf.project_research_content[0].problem_statement_content}
        </p>
      </div> */}
    </div>
  );
}
export default UxContent;
