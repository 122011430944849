function Info({ restData }) {
  const listLength = restData.acf.info_section.length;

  const infoList = restData.acf.info_section.map((e, i) => {
    let borderOne = "border-bg border mb-9 md:hidden";
    let borderTwo =
      "mx-4 s:max-w-[18.75rem] s:mx-auto md:grow md:!mx-0 md:w-1/4";
    let contentWidth = "";

    if (i + 1 === listLength) {
      borderOne = "";
    } else {
      borderTwo = borderTwo + " md:border-r-bg md:border-r";
    }

    if (i + 1 === 2 || i + 1 === 4) {
      contentWidth = "md:mx-4";
    }

    return (
      <div key={`info-${i}`} className={borderTwo}>
        <div className={contentWidth}>
          <h2 className="text-blanc text-left md:text-center md:mt-10">
            {e.info_section_title}
          </h2>
          <p className="text-blanc text-left mt-4 mb-6 md:text-center">
            {e.info_content}
          </p>
        </div>
        <div className={borderOne} />
      </div>
    );
  });

  return infoList;
}

export default Info;
