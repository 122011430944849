import infoArchitecture from "./../../assets/informationArchitecture.png";

function UxSolution({ restData, slug }) {
  let result = "";
  if (slug === "florist") {
    result = (
      <div className="mx:py-5">
        <h2 className="mt-14 mb-8 text-center">
          {" "}
          {restData.acf.project_solution_content[0].information_architecture}
        </h2>
        <p className="text-left">
          {
            restData.acf.project_solution_content[0]
              .information_architecture_text
          }
        </p>
        {/* IA image*/}
        <img
          src={infoArchitecture}
          alt="graph of information architecture"
          className="mt-8 mx-auto mx:mt-16"
        />
      </div>
    );
  }

  return (
    <div className="s:w-11/12 mx:max-w-[1120px] mx-auto">
      {/* MVP */}
      {/* <div className="mx:py-5">
        <h3 className="mt-14 mb-8 text-left">
          {restData.acf.project_solution_content[0].defining_the_mvp}
        </h3>
        <p className="text-left">
          {restData.acf.project_solution_content[0].mvp_content}
        </p>
      </div> */}
      {/* information architecture */}
      {result}
    </div>
  );
}
export default UxSolution;
