import loading from "../assets/loading.gif";

const Loading = () => {
  return (
    <img
      src={loading}
      alt="Loading"
      className="block mx-auto mt-60 w-60 h-60 m:w-64 m:h-64 dx:w-80 dx:h-80"
      id="loading"
    />
  );
};

export default Loading;
