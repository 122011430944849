import { useState, useEffect } from "react";
import Loading from "./Loading";
import { Link, NavLink, useParams } from "react-router-dom";
import { fetchData, findDataObject } from "../utilities/fetchUtilities";
import { Tooltip } from "@material-tailwind/react";
import CopyEmail from "./CopyEmail";

function SideNav({
  slug,
  restBase,
  restMediaSideBarData,
  worksSectionVisible,
  credit,
}) {
  //footer
  const restContactPath = restBase + "pages/143?_embed";
  const restPath = restBase + "media?parent=143";
  //sidenav
  const restMediaPath = restBase + "media?parent=141";
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [restMediaData, setMediaData] = useState([]);
  const [isMediaLoaded, setMediaLoadStatus] = useState(false);
  const [restContactData, setContactData] = useState([]);
  const [isContactLoaded, setContactLoadStatus] = useState(false);

  useEffect(() => {
    fetchData(restPath, setData, setLoadStatus);
    fetchData(restMediaPath, setMediaData, setMediaLoadStatus);
    fetchData(restContactPath, setContactData, setContactLoadStatus);
  }, [restPath, restMediaPath, restContactPath]);

  const param = useParams();
  const objectKeyNum = Object.keys(param).length;
  const creditPage = "/credits";

  // img id
  let logoID = "";
  let homeID = "";
  let workID = "";
  let aboutID = "";
  let contactID = "";
  let linkedinID = "";
  let githubID = "";

  let logoData = {};
  let homeData = {};
  let workData = {};
  let aboutData = {};
  let contactData = {};
  let linkedinData = {};
  let githubData = {};

  if (isLoaded && isMediaLoaded && isContactLoaded) {
    logoID = restMediaSideBarData.acf.site_logo;
    homeID = restMediaSideBarData.acf.sidenav_home;
    workID = restMediaSideBarData.acf.sidenav_works;
    aboutID = restMediaSideBarData.acf.sidenav_about;
    contactID = restMediaSideBarData.acf.sidenav_contact;
    linkedinID = restContactData.acf.linkedin;
    githubID = restContactData.acf.github;

    logoData = findDataObject(restMediaData, logoID);
    homeData = findDataObject(restMediaData, homeID);
    workData = findDataObject(restMediaData, workID);
    aboutData = findDataObject(restMediaData, aboutID);
    contactData = findDataObject(restMediaData, contactID);
    linkedinData = findDataObject(restData, linkedinID);
    githubData = findDataObject(restData, githubID);
  } else {
  }

  return (
    <>
      {isLoaded && isMediaLoaded && isContactLoaded ? (
        <nav className="collapse md:visible z-50 w-14 fixed bottom-0 left-0 top-0 flex flex-col justify-between bg-blanc dt:w-20 dx:w-24">
          <NavLink to="/#home">
            <img
              src={logoData.guid.rendered}
              alt="Site logo"
              className="mobile-logo"
              width="32"
              height="36.234"
            />
          </NavLink>
          {worksSectionVisible || objectKeyNum !== 0 || credit === "credit" ? (
            <ul>
              <li>
                <Tooltip
                  content="Home"
                  placement="right"
                  className="font-cambay bg-white shadow text-text px-4 ml-0 text-center"
                  animate={{
                    mount: { width: "90px", transition: "all 5s ease-out" },
                    unmount: { width: "77px", transition: "all 5s ease-in" },
                  }}
                >
                  <Link to="/#home">
                    <img
                      src={homeData.guid.rendered}
                      alt="Home"
                      className="sideNav-icon"
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="Works"
                  placement="right"
                  className="font-cambay bg-white shadow text-text px-4 ml-0 text-center"
                  animate={{
                    mount: { width: "90px", transition: "all 5s ease-out" },
                    unmount: { width: "77px", transition: "all 5s ease-in" },
                  }}
                >
                  <Link to="/#featured-work">
                    <img
                      src={workData.guid.rendered}
                      alt="Works"
                      className="sideNav-icon"
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="About"
                  placement="right"
                  className="font-cambay bg-white shadow text-text px-4 ml-0 text-center"
                  animate={{
                    mount: { width: "90px", transition: "all 5s ease-out" },
                    unmount: { width: "77px", transition: "all 5s ease-in" },
                  }}
                >
                  <Link to="/#about-me">
                    <img
                      src={aboutData.guid.rendered}
                      alt="About"
                      className="sideNav-icon"
                    />
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="Contact"
                  placement="right"
                  className="font-cambay bg-white shadow text-text px-4 ml-0 text-center"
                  animate={{
                    mount: { width: "90px", transition: "all 5s ease-out" },
                    unmount: { width: "77px", transition: "all 5s ease-in" },
                  }}
                >
                  <Link
                    to={
                      objectKeyNum !== 0 ||
                      window.location.pathname === creditPage
                        ? "#contact"
                        : "/#contact"
                    }
                  >
                    <img
                      src={contactData.guid.rendered}
                      alt="Contact icon"
                      className="sideNav-icon"
                    />
                  </Link>
                </Tooltip>
              </li>
            </ul>
          ) : (
            <ul aria-label="my contact">
              <li>
                <Link
                  to="https://www.linkedin.com/in/nathalie-ch-chang/"
                  target="_blank"
                >
                  <img
                    src={linkedinData.guid.rendered}
                    alt="LinkedIn"
                    title="LinkedIn"
                    className="sideNav-icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="https://github.com/Nathalie-C" target="_blank">
                  <img
                    src={githubData.guid.rendered}
                    alt="GitHub"
                    title="GitHub"
                    className="sideNav-icon"
                  />
                </Link>
              </li>
              <li>
                <CopyEmail
                  restData={restContactData}
                  restMediaData={restData}
                  sideNav={true}
                />
              </li>
            </ul>
          )}
        </nav>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SideNav;
