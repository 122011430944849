import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SingleWork from "./pages/SingleWork";
import ErrorPage from "./pages/ErrorPage";
import Credits from "./pages/Credits";

function App() {
  const restBase = "https://nathaliechang.org/portfolio/wp-json/wp/v2/";

  const errorPage = <ErrorPage />;

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home restBase={restBase} />} />
        <Route path="/:slug" element={<SingleWork restBase={restBase} />} />
        <Route
          exact
          path="/credits"
          element={<Credits restBase={restBase} />}
        />
        <Route path="*" element={errorPage} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
