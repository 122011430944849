import Typography from "../elements/Typography";

function Branding({ restData, webLogoData }) {
  const paletteList = restData.acf.palette.map((e, i) => {
    const hexCode = e.color.toLowerCase().trim();
    return (
      <li key={i}>
        <div
          className={`w-14 h-14 rounded-full border-lighthr border-2 mx-auto`}
          style={{ backgroundColor: hexCode }}
        />
        <p className="mt-3 mb-1 w-20 text-base">{e.color}</p>
      </li>
    );
  });

  return (
    <div className="bg-blanc s:w-11/12 mx:max-w-[1120px] mx-auto mx:!my-10 border-solid border-4 border-blanc rounded-lg">
      {/* <h2 className="mt-20 mb-8 text-center ml:mt-36">
        {restData.acf.project_solution_content[0].design_solution}
      </h2> */}
      {/* <p className="text-left">
        {restData.acf.project_solution_content[0].design_solution_content}
      </p> */}

      {/* grid */}
      <div className="w-10/12 mx-auto md:mt-10 md:grid md:grid-cols-3 md:gap-4">
        {/* logo */}
        <h4 className="mt-10 mb-6 text-center md:text-left md:justify-start md:self-center md:m-0">
          {restData.acf.project_solution_content[0].design_logo}
        </h4>
        <div className="md:col-span-2">
          <div className="flex flex-col items-center md:flex-row md:gap-4 md:justify-center ml:gap-8">
            <img
              src={webLogoData.guid.rendered}
              alt="logo"
              className="w-24 h-24 mb-8 justify-self-center md:mb-0"
            />
            {/* <p className="text-left w-[255px] md:m-0">
                {
                  restData.acf.project_solution_content[0]
                    .design_solution_logo_content
                }
              </p> */}
          </div>
        </div>
        {/* color palette */}
        <h4 className="mt-11 mb-8 text-center md:text-left md:justify-start md:self-center md:m-0">
          {restData.acf.color_palette}
        </h4>
        <div className="md:justify-start md:items-center md:col-span-2">
          <ul className="flex flex-wrap justify-center gap-2 mt-6 mb-8 md:items-center m:gap-5 m:w-[440px] m:mx-auto">
            {paletteList}
          </ul>
        </div>
        {/* typography */}
        <h4 className="mt-11 mb-8 text-center md:text-left md:justify-start md:self-center md:m-0">
          {restData.acf.typography}
        </h4>
        <div className="mb-10 flex flex-col items-center md:col-span-2 md:items-center md:justify-start">
          <Typography restData={restData} />
        </div>
      </div>
    </div>
  );
}
export default Branding;
