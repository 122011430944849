function AboutIntro({ restData }) {
  const infoList = restData.acf.about_section_intro.map((e, i) => {
    return (
      <div key={`intro-${i}`}>
        <h3 className="text-left mb-4">{e.about_section_intro_heading}</h3>
        <p className="text-left mb-10">{e.about_section_intro_text}</p>
      </div>
    );
  });

  return infoList;
}

export default AboutIntro;
